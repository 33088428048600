body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ec8b5e;
  background: #141a46;
  background: radial-gradient(circle, #141a46 0%, #141a46 55%, #0f1333 100%);
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#back-button {
  position: fixed;
  top: 10vh;
  left: 5vw;
  color: #141a46;
  background: #ec8b5e;
  border: 2px solid #ec8b5e;
}

#back-button:hover {
  color: #ec8b5e;
  background: #141a46;
  border: 2px solid #ec8b5e;
}

#login-container {
  height: 80vh;
  margin-top: 8vh;
  display: flex;
  align-items: center;
}

#login-button {
  background: #ec8b5e !important;
  color: #141a46 !important;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 30px;
  margin-top: 10vh;
}

#input-text {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 60px;
  color: #ec8b5e !important;
  text-align: center;
}

#content-container {
  height: 80vh;
  margin-top: 8vh;
}

#factor-grid {
  margin: 20px;
  margin-top: 40px;
}

#factor-status {
  color: white;
  text-align: center;
  padding: 40px;
  font-size: 150%;
  background: #ec8b5e;
}

#factor-status:hover {
  background: #d97f57;
}

#factor-status-score {
  font-size: 300%;
  padding-bottom: 20px;
}

#factor-status a,
#factor-status a:link,
#factor-status a:visited {
  color: white;
}

.red-factor #factor-status-score a,
.red-factor #factor-status-score a:link,
.red-factor #factor-status-score a:visited {
  color: #ec5e5e;
}

.yellow-factor #factor-status-score a,
.yellow-factor #factor-status-score a:link,
.yellow-factor #factor-status-score a:visited {
  color: #ecd45e;
}

.green-factor #factor-status-score a,
.green-factor #factor-status-score a:link,
.green-factor #factor-status-score a:visited {
  color: #94ec5e;
}

#graph-title {
  font-size: 70px;
  margin-left: 2vw;
}

#line-graph-score {
  position: fixed;
  top: 30vh;
  left: 10vw;
  width: 30vw;
  font-size: 200px;
  text-align: center;
}

#line-graph-container {
  position: fixed;
  top: 45vh;
  left: 10vw;
  height: 45vh;
  width: 30vw;
}

#sensor-feedback {
  position: relative;
  top: 10vh;
  float: right;
  font-family: inherit;
}

#sensor-feedback header {
  font-size: 60px;
  margin: 7vh;
}

#sensor-feedback ul li {
  font-size: 40px;
  margin: 5vh;
}
